// Here you can add other styles

.table {
    border-top: none;
    thead {
        th {
            border-top: none;
            background: #eee;
        }
    }
}

p.error {color: $red; font-weight: 700}

.form-group.error{
    .input-feedback {color: $red; font-size: 12px; font-style: italic;}
    .form-control {border-color: $red}

    .input-group {
        .form-control {border-left: 0}
        .input-group-text {border-color: $red}
    }
}

.toast {
    .toast-icons {
        position: absolute;
        top: 5px;
        right: 50px;
    }
}

.fileContainer {
    .uploadPictureContainer {
        width: 20% !important; margin: 2% !important; padding: 5px !important;
    }

    .deleteImage {
        font-size: 14px !important; font-weight: 400 !important; background: #CCC !important; line-height: 20px !important; width: 20px !important; height: 20px !important;
    }
}
